/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extend_6723d95a from '/home/nde/pim/frontend/modules/@ergonode/attributes/src/config/extends.js';
import extend_b44cad3e from '/home/nde/pim/frontend/modules/@ergonode/attribute-groups/src/config/extends.js';
import extend_eb54c350 from '/home/nde/pim/frontend/modules/@ergonode/categories/src/config/extends.js';
import extend_3abbab7c from '/home/nde/pim/frontend/modules/@ergonode/category-trees/src/config/extends.js';
import extend_36c2b04a from '/home/nde/pim/frontend/modules/@ergonode/core/src/config/extends.js';
import extend_5170d04c from '/home/nde/pim/frontend/modules/@ergonode/dashboard/src/config/extends.js';
import extend_174d73cc from '/home/nde/pim/frontend/modules/@ergonode/channels/src/config/extends.js';
import extend_abd818ac from '/home/nde/pim/frontend/modules/@ergonode/media/src/config/extends.js';
import extend_7b7198ae from '/home/nde/pim/frontend/modules/@ergonode/notifications/src/config/extends.js';
import extend_125f6a4a from '/home/nde/pim/frontend/modules/@ergonode/collections/src/config/extends.js';
import extend_dfdc0b40 from '/home/nde/pim/frontend/modules/@ergonode/products/src/config/extends.js';
import extend_67c71ce1 from '/home/nde/pim/frontend/modules/@ergonode/product-templates/src/config/extends.js';
import extend_441f79c3 from '/home/nde/pim/frontend/modules/@ergonode/roles/src/config/extends.js';
import extend_263a1ece from '/home/nde/pim/frontend/modules/@ergonode/users/src/config/extends.js';
import extend_2797aca1 from '/home/nde/pim/frontend/modules/@ergonode/import/src/config/extends.js';
import extend_9e7400a0 from '/home/nde/pim/frontend/modules/@ergonode/batch-actions/src/config/extends.js';
import extend_42e853c3 from '/home/nde/pim/frontend/modules/@ergonode/products-comments/src/config/extends.js';
import extend_dbaff65c from '/home/nde/pim/frontend/modules/@ergonode/product-batch-actions/src/config/extends.js';
import extend_433afa7b from '/home/nde/pim/frontend/modules/@ergonode/workflow/src/config/extends.js';

export default {
	extend_6723d95a,
	extend_b44cad3e,
	extend_eb54c350,
	extend_3abbab7c,
	extend_36c2b04a,
	extend_5170d04c,
	extend_174d73cc,
	extend_abd818ac,
	extend_7b7198ae,
	extend_125f6a4a,
	extend_dfdc0b40,
	extend_67c71ce1,
	extend_441f79c3,
	extend_263a1ece,
	extend_2797aca1,
	extend_9e7400a0,
	extend_42e853c3,
	extend_dbaff65c,
	extend_433afa7b,
};
